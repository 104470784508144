<template>
  <teleport to="body">
    <div
      class="flex justify-center items-center z-50 fixed top-0 w-full h-full bg-blue-darkest bg-opacity-80"
    >
      <div
        class="flex flex-col justify-center items-center bg-white shadow-md w-36 h-36 rounded-lg"
      >
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
        <p class="text-yellow mt-2">{{ t("loader.text") }}</p>
      </div>
    </div>
  </teleport>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "loader",
  setup() {
    const { t } = useI18n();

    return { t };
  }
};
</script>

<style scoped>
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #f5ae00;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
