<template>
  <ul
    style="z-index: 100;"
    class="fixed z-50 mr-2 mt-1 right-0 top-0 font-semibold flex flex-col"
  >
    <li
      v-for="message in errors"
      :key="message"
      class="rounded-md mt-2 p-4 text-red-900 bg-white border-red-500 border-l-4 shadow-lg"
    >
      {{ message }}
    </li>
    <li
      v-for="message in success"
      :key="message"
      class="rounded-md mt-2 p-4 text-green-light bg-white border-green border-l-4 shadow-lg "
    >
      {{ message }}
    </li>
    <li
      v-for="message in info"
      :key="message"
      class="rounded-md mt-2 p-4 text-gray bg-white border-gray border-l-4 shadow-lg"
    >
      {{ message }}
    </li>
  </ul>
  <Loader v-if="processing" />
  <ReAuthenticationModal />
  <nav
    class=" w-full pt-1 box-border flex items-center justify-between font-sans"
    style="height: 8vh"
    role="navigation"
    aria-label="main navigation"
  >
    <router-link class=" w-1/5 ml-3" :to="{ name: 'Dashboard' }">
      <img
        src="@/assets/icon.svg"
        alt="Farmhelp logo"
        class="w-12 is-centered"
      />
    </router-link>
    <div class=" w-3/5">
      <h2 class="text-sm font-bold" v-if="unitsList.length == 1">
        {{ farmName }}
      </h2>
    </div>
    <!-- <NotificationBell
      v-if="location.hostname !== 'app.farmhelp.dk'"
      class="mr-3"
    /> -->
    <span class="relative mr-3">
      <img
        class="cursor-pointer"
        @click="chooseLang = !chooseLang"
        :src="lang.url"
      />
      <ul v-show="chooseLang" class="absolute z-50 mt-3">
        <li
          v-for="url in urls.filter(u => u.lang !== lang.lang)"
          :key="url"
          class="opacity-60 hover:opacity-100"
        >
          <img
            class="cursor-pointer"
            @click="
              locale = url.lang;
              lang = url;
              chooseLang = false;
            "
            :src="url.url"
          />
        </li>
      </ul>
    </span>
    <div class="w-1/5 flex items-center justify-center pr-3">
      <button
        @click="mobileMenu = !mobileMenu"
        class="open-main-nav flex flex-col items-center "
        :class="[mobileMenu ? 'is-open' : '']"
      >
        <span
          class="relative flex justify-center items-center text-sm bg-green rounded-full w-10 h-10 z-10"
        >
          <i class="fas fa-user"></i>
          <span
            v-if="profile !== null"
            class="text-white text-sm absolute -bottom-3 bg-green-dark rounded-md px-2"
            >{{ profile.firstName }}</span
          >
        </span>
      </button>
      <nav class="main-nav" :class="[mobileMenu ? 'is-open' : '']">
        <span
          @click="mobileMenu = !mobileMenu"
          class="absolute cursor-pointer top-4 right-8 flex flex-shrink-0 justify-center items-center text-2xl"
        >
          <i class="fas fa-times"></i>
        </span>
        <ul class="ml-20">
          <li
            @click="mobileMenu = !mobileMenu"
            class="flex bg-green rounded-md px-4 mb-4 w-auto flex-grow-0"
          >
            <span class="flex justify-center items-center text-xl mr-4">
              <i class="fas fa-user-cog"></i>
            </span>
            <router-link :to="{ name: 'UserProfile' }">
              Profil indstillinger
            </router-link>
          </li>

          <li
            class="flex bg-green rounded-md px-4 mb-4 w-auto flex-grow-0"
            @click="signOutSubmit"
          >
            <span class="flex justify-center items-center text-2xl mr-4">
              <i class="fas fa-sign-out-alt"></i>
            </span>
            <a href="#">Logud</a>
          </li>
        </ul>
      </nav>
    </div>
    <Modal @closeModal="firstSignIn" :visible="firstSignIn">
      <h1 class="text-yellow text-3xl mt-4 font-bold tracking-wider">
        Velkommen til farmhelp!
      </h1>
      <span
        class=" text-green border-2 border-green rounded-full w-14 h-14 flex justify-center items-center text-xl my-4"
      >
        <i class="fas fa-key"></i>
      </span>
      <p class="text-center font-semibold">
        Du kan ændre din kode nu,<br />
        eller gøre det senere i profil-indstillinger.
      </p>
      <div class="flex flex-col items-center mx-4 w-2/3">
        <div class="w-full">
          <Input
            class="my-4"
            icon="fa-lock"
            type="password"
            placeholder="Ny kode . . ."
            v-model="password1"
          />
          <Input
            icon="fa-lock"
            type="password"
            placeholder="Gentag kode . . ."
            v-model="password2"
          />
        </div>
        <p class=" ml-4 w-full text-left">
          Koden skal opfylde følgende krav:
        </p>
        <ul class="ml-8 text-white">
          <li class="flex">
            <span
              v-if="errorMinimumCharacters === false"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
            >
              <i class="fas fa-exclamation"></i>
            </span>
            <span
              v-if="errorMinimumCharacters"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
            >
              <i class="fas fa-check"></i>
            </span>
            Minimum 8 tegn
          </li>
          <li class="flex">
            <span
              v-if="errorContainsNumber === false"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
            >
              <i class="fas fa-exclamation"></i>
            </span>
            <span
              v-if="errorContainsNumber"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
            >
              <i class="fas fa-check"></i>
            </span>
            Indeholde et tal ( 0 - 9 )
          </li>
          <li class="flex">
            <span
              v-if="errorContainsLowercase === false"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
            >
              <i class="fas fa-exclamation"></i>
            </span>
            <span
              v-if="errorContainsLowercase"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
            >
              <i class="fas fa-check"></i>
            </span>
            Indeholde små bogstaver ( a - z )
          </li>
          <li class="flex">
            <span
              v-if="errorContainsUppercase === false"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
            >
              <i class="fas fa-exclamation"></i>
            </span>
            <span
              v-if="errorContainsUppercase"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-green"
            >
              <i class="fas fa-check"></i>
            </span>
            Indeholde store bogstaver ( A - Z )
          </li>
          <li v-if="errorEqualPassword === false" class="flex">
            <span
              v-if="errorEqualPassword === false"
              class="flex flex-shrink-0 justify-center items-center mr-4 text-red-500"
            >
              <i class="fas fa-exclamation"></i>
            </span>
            De to koder er ikke ens
          </li>
        </ul>
      </div>

      <Button
        label="Ændre kode"
        class="my-4 bg-gradient-to-r from-yellow-dark to-yellow"
        @click="changePassword(password1, password2)"
      />
    </Modal>
  </nav>
</template>

<script>
import { farm } from "@/global/services/farm";
import { errorLogging } from "@/global/services/errorLogging";
import { authentication } from "@/global/services/authentication";
import { units } from "@/global/services/units";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import Button from "@/components/Button";
import Loader from "@/components/Loader";
import ReAuthenticationModal from "@/global/components/re-authentication-modal";
import { useRouter } from "vue-router";
import { ref, computed, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import {
  validateContainsLowercase,
  validateContainsNumber,
  validateContainsUppercase,
  validateMinimumCharacters
} from "@/global/services/mixins";

export default {
  name: "mobile-top-bar",
  components: {
    Modal,
    Input,
    Button,
    Loader,
    ReAuthenticationModal
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const state = {
      location,
      locale,
      lang: ref({
        lang: "dk",
        url: require("@/assets/dk.png")
      }),
      chooseLang: ref(false),
      urls: [
        {
          lang: "dk",
          url: require("@/assets/dk.png")
        },
        {
          lang: "en",
          url: require("@/assets/en.png")
        }
      ],
      farmName: ref(farm.getFarmName()),
      errorContainsLowercase: ref(undefined),
      errorContainsNumber: ref(undefined),
      errorContainsUppercase: ref(undefined),
      errorMinimumCharacters: ref(undefined),
      errorEqualPassword: ref(undefined),
      profile: authentication.getUserProfile(true),
      password1: ref(""),
      password2: ref(""),
      processing: ref(false),
      mobileMenu: ref(false),
      userMenu: ref(false),
      name: ref(""),
      firstSignIn: ref(false),
      unitsList: units.getUnitsList(true)
    };

    state.lang.value = locale.value;

    watchEffect(() => {
      state.lang.value = state.urls.find(u => u.lang === locale.value);
    });

    document.addEventListener("processing", event => {
      state.processing.value = event.detail;
    });

    watchEffect(() => {
      if (state.password1.value !== "") {
        state.errorContainsLowercase.value = validateContainsLowercase(
          state.password1.value
        );
        state.errorContainsNumber.value = validateContainsNumber(
          state.password1.value
        );
        state.errorContainsUppercase.value = validateContainsUppercase(
          state.password1.value
        );
        state.errorMinimumCharacters.value = validateMinimumCharacters(
          state.password1.value
        );
      }
    });
    watchEffect(() => {
      if (state.password2.value !== "") {
        if (state.password2.value !== state.password1.value) {
          state.errorEqualPassword.value = false;
        } else {
          state.errorEqualPassword.value = true;
        }
      }
    });
    state.firstSignIn = computed(() => {
      if (state.profile.value !== null) {
        return state.profile.value.firstSignIn;
      } else {
        return false;
      }
    });

    const router = useRouter();

    const signOutSubmit = () => {
      authentication
        .signOut()
        .then(() => {
          router.push({ name: "Login" });
        })
        .catch(error => {
          console.log(error);
        });
    };

    return {
      t,
      errors: errorLogging.getErrors(),
      success: errorLogging.getSuccess(),
      info: errorLogging.getInfo(),
      ...state,
      signOutSubmit,
      changePassword: authentication.changePassword
    };
  }
};
</script>

<style scoped>
/* Main menu positionning */
.main-nav {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: left;
  background: #00262d;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all 0.375s;
  overflow: hidden;
}

.main-nav.is-open {
  opacity: 1;
  z-index: 50;
  visibility: visible;
}

/* Yellow band effect */
.main-nav::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #00262d, #013f4b);
  transform-origin: top right;
  transform: skew(-14deg) translateX(120%);
  transition: all 0.275s 0.1s;
}

.main-nav.is-open::before {
  transform: skew(-14deg) translateX(0);
}

/* Skewing effect on menu links */
.main-nav ul {
  display: inline-flex;
  flex-direction: column;
  height: 100%; /* Should be 100%, but we have a notice message :D */
  justify-content: center;
}

.main-nav li {
  transform: skew(0deg);
}

/* Apparition effect on links */
.main-nav a {
  opacity: 0;
  transform: translateY(-10px);
}

.main-nav.is-open a {
  opacity: 1;
  transform: translateY(0);
}
.main-nav li a {
  transition: all 275ms 175ms;
}

/* Decoration */
.main-nav ul,
.main-nav li {
  list-style: none;
}
.main-nav a {
  display: block;
  padding: 12px 0;
  text-decoration: none;
  font-weight: bold;
}

/* Burger Style: @see: https://codepen.io/CreativeJuiz/full/oMZNXy */
.open-main-nav {
  z-index: 40;
  background: none;
  border: 0;
  cursor: pointer;
}
.open-main-nav:focus {
  outline: none;
}
</style>
