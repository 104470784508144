<template>
  <teleport to="body">
    <Modal @closeModal="closeModal()" :visible="showModal">
      <span
        class=" text-green border-2 border-green rounded-full w-14 h-14 flex justify-center items-center text-xl mb-4"
      >
        <i class="fas fa-key"></i>
      </span>
      <h1 class="text-yellow text-2xl font-bold tracking-wider">
        {{ t("re-authentication-modal.header") }}
      </h1>
      <p class="text-white text-center font-semibold mb-4">
        {{ t("re-authentication-modal.description") }}
      </p>
      <Input
        @keyup.enter="confirmAuthentication()"
        :error="errorCode === 'auth/wrong-password'"
        :errorMessage="errorCode === 'auth/wrong-password' ? errorMessage : ''"
        class="w-full mb-3"
        labelWidth="w-4/12"
        inputWidth="w-8/12"
        icon="fa-lock"
        type="password"
        :placeholder="t('re-authentication-modal.currentPassword')"
        v-model="password"
      />
      <div class="my-3 flex">
        <Button
          class="bg-green-light mr-3"
          :label="t('re-authentication-modal.confirm')"
          @click="confirmAuthentication()"
        />
        <Button
          class="bg-red-500 ml-3"
          :label="t('re-authentication-modal.cancel')"
          @click="closeModal()"
        />
      </div>
    </Modal>
  </teleport>
</template>

<script>
import { ref, defineAsyncComponent } from "vue";
import { systemState } from "@/global/services/systemState";
import { authentication } from "../services/authentication";
import { errorLogging } from "../services/errorLogging";
import { useI18n } from "vue-i18n";

export default {
  name: "re-authentication-modal",
  components: {
    Modal: defineAsyncComponent(() => import("@/components/Modal")),
    Button: defineAsyncComponent(() => import("@/components/Button")),
    Input: defineAsyncComponent(() => import("@/components/Input"))
  },
  props: {},
  setup() {
    const { t } = useI18n();
    const state = {
      showModal: ref(false),
      password: ref(""),
      errorCode: ref(null),
      errorMessage: ref("")
    };

    const functions = {
      closeModal: function() {
        systemState.dispatchReAuthenticationAborted();
        document.removeEventListener("reAuthenticationComplete", null);
        state.showModal.value = false;
      },
      confirmAuthentication: function() {
        systemState.startProcessing();
        authentication
          .reAuthenticate(state.password.value)
          .then(() => {
            systemState.stopProcessing();
            state.showModal.value = false;
            systemState.dispatchReAuthenticationComplete();
          })
          .catch(error => {
            systemState.stopProcessing();
            errorLogging.setWarning(error.message);
            state.errorCode.value = error.code;
            state.errorMessage.value = error.message;
          });
      }
    };

    document.addEventListener("reAuthenticate", event => {
      state.showModal.value = event.detail;
    });

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped></style>
